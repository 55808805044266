import React, { useState, useEffect } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import style from './BankInfo.module.scss';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import { endPoint } from 'utils/config';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_live_51KsyTMF4tojQdNu9mHfopN4b53S9G9ZhOSW1EYYnthaCgAuSySX3HvNMnN905W6JYjZy4goFMfNUa4cVuVqkO4xP00FyvAmtuw');

const BankInfo = (props) => {
	let code = queryString.parse(props.location.search).code;
	let driverToken = queryString.parse(props.location.search).token;

	const [accountLink, setAccountLink] = useState('')
	const [isCompleted, setIsCompleted] = useState(false);
	const [finalCode, setFinalCode] = useState('');

	const [currentPath, setCurrentPath] = useState('');
	const [currentSearch, setCurrentSearch] = useState('');

	console.log(props.history);

	// useEffect(() => {
	const { history } = props;

	history.listen((newLocation, action) => {
		// if (action === 'PUSH') {
		if (newLocation.pathname === '/api/v1/paymentMethod' || newLocation.search !== currentSearch) {
			// Save new location
			// currentPath = newLocation.pathname;
			// currentSearch = newLocation.search;
			setCurrentPath(newLocation.pathname);
			setCurrentSearch(newLocation.search);

			// Clone location object and push it to history
			history.push({
				pathname: newLocation.pathname,
				search: newLocation.search,
			});
		}
		// } else {
		// 	// Send user back if they try to navigate back
		// 	history.go(1);
		// }
	});
	// }, [,]);

	useEffect(() => {
		if (code) {
			setIsCompleted(true);
			console.log('isCompleted', code);
			// setFinalCode(code);
			handleAccountVerification(code);
		}
	}, [,]);

	const handleAccountVerification = async (code) => {
		try {
		  const response = await axios.post(
			`${window.env.API_URL}/connect/verify-account`,
			{ code },
			{
			  headers: {
				'Access-Control-Allow-Origin': '*',
				authorization: window.localStorage.getItem('token'),
			  },
			}
		  );
	
		  if (response.data.redirect) {
			window.location = endPoint + `/payment/success`;
		  } else {
			window.location.href = endPoint + `/shopAdmin/${
			  window.localStorage.getItem('shopType') === 'Restaurant' ? 'Menu' : 'Categories'
			}`;
		  }
		} catch (error) {
		  console.error(error);
		  if (props.forDriver) {
			window.location = endPoint + `/payment/fail`;
		  }
		}
	};

	useEffect(() => {
		if (props.forDriver && driverToken) {
			window.localStorage.setItem('token', driverToken.toString());
		}
	}, [,]);

	const handleGoBackToApp = () => {
		window.location = 'sparkdriver://payment/fail';
	};

	const handleSubmit = async () => {
		if (isCompleted) return;
	
		try {
		  const response = await axios.post(
			`${window.env.API_URL}/connect/create-account`,
			{},
			{
			  headers: {
				'Access-Control-Allow-Origin': '*',
				authorization: window.localStorage.getItem('token'),
			  },
			}
		  );
	
		  // Redirect to the Stripe hosted onboarding
		  window.location.href = response.data.accountLink;
		} catch (error) {
		  console.error('Error creating connect account:', error);
		}
	};

	return (
		<div className={style.mainDiv}>
			<GridContainer justify="center">
				<GridItem xs={10} sm={9} md={6} lg={5}>
					<div className={style.title}>Bank account info</div>
					<div style={{ margin: '20px 0' }}>
						{isCompleted
							? 'Good, now press confirm button to go to the next step.'
							: 'In order to be able to pay you, you need to create a Stripe account or login if you already have one, it only takes a minute.'}
					</div>
					<div className={style.buttonContainer}>
						<Button className={style.blueButton} onClick={handleSubmit} color="warning">
							{isCompleted ? 'Confirm' : 'Create account'}
						</Button>
					</div>
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default BankInfo;
