window.env = {
  // -------------
  // API_URL: "https://testapi.sparkapps.ca",
  // SITE_URL: "https://testpanel.sparkapps.ca",
  // API_SOCKET_URL: "wss://api.sparkapps.ca/subscription",
  // -------------
  API_URL: "https://testapi.sparkapps.ca",
  SITE_URL: "https://testpanel.sparkapps.ca",
  API_SOCKET_URL: "wss://testws.sparkapps.ca",

  // -------------------
  // API_URL: "http://172.20.10.6:4000",
  // SITE_URL: "http://127.0.0.1:4001",
  // API_SOCKET_URL: "ws://172.20.10.6:4000/graphql",
  // -------------------
  // API_URL: "http://144.217.133.96:4000", 
  // SITE_URL: "http://144.217.133.96:4001",
  // API_SOCKET_URL: "ws://144.217.133.96:4000/graphql",
};

console.log('window.API_URL, SITE_URL, API_SOCKET_URL: ', window.API_URL, ' ', window.SITE_URL, " ", window.API_SOCKET_URL)
export const endPoint = window.env.SITE_URL;
export const graphqlUrl = window.env.API_URL;
